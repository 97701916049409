<template>
  <el-select
    ref="select_element"
    v-model="value"
    :clearable="clearable"
    @clear="resetForm"
    :filterable="searchable"
    :filter-method="filterLine"
    @change="handleSelect"
    placeholder="请输入"
    :loading="loading"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.companyName"
      :value="item[label]"
    ></el-option>
  </el-select>
</template>

<script>
/**
 * 选择线路组件   模糊搜索 单选
 * 回显功能
 *
 */
import { queryLineDetailFuzzy } from '@/api/lib/refreshAlarm.js'
export default {
  props: {
    label: {
      type: String,
      default: 'companyId'
    },
    /* 已选中线路 */
    choosedLine: {
      type: String,
      default: ''
    },
    /* 可清空选项 */
    clearable: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    searchable: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    //新增运营参数
    isParams: {
      type: Boolean,
      default: () => {
        return false
      }
    },

  },
  created () { },

  data () {
    return {
      value: null, // 初始值
      options: [],
      loading: false
    }
  },

  methods: {
    /** 清空input */
    resetForm () {
      this.value = null
      this.$emit('getData', null)
    },

    /** 搜索方法 */
    filterLine (query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          let data = { lineName: query }
          queryLineDetailFuzzy(data).then((res) => {
            if (res.code === 1000) {
              this.options = res.data
              this.value=null
            }
          })
        }, 200)
      } else {
        this.options = []
      }
    },
    handleSelect () {
      let type = null
      if (this.isParams) {
        let item = this.options.find(value => value[this.label] == this.value)
        if (item) type = item.lineType
      }
      this.$emit('getData', this.value, Number(type))
    }
  },
  watch: {
    choosedLine (val, oldVal) {
      if (val) {
        let data = { lineName: val }
        queryLineDetailFuzzy(data).then((res) => {
          if (res.code === 1000) {
            this.options = res.data
            let type = null
            if (this.isParams) {
              type = res.data[0].lineType
            }
            this.value = res.data[0][this.label]
            this.$emit('getData', this.value, Number(type))
          }
        })
      }
    }
  }
}
</script>
