var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      ref: "select_element",
      attrs: {
        clearable: _vm.clearable,
        filterable: _vm.searchable,
        "filter-method": _vm.filterLine,
        placeholder: "请输入",
        loading: _vm.loading
      },
      on: { clear: _vm.resetForm, change: _vm.handleSelect },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    _vm._l(_vm.options, function(item) {
      return _c("el-option", {
        key: item.id,
        attrs: { label: item.companyName, value: item[_vm.label] }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }