<template>
  <div class="project-management" ref="projectManage">
    <div class="function-buttons" ref="functionButtons" v-show="false"></div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <el-form-item label="线路名称：">
          <lineSelect @getData="getLineData"></lineSelect>
        </el-form-item>
      </el-form>
    </div>
    <div class="mapHintCard">
      <div class="hintItem" v-for="(item, i) in hintOptions" :key="i">
        <img :src="item.url" />
        <div>{{ item.val }}</div>
      </div>
    </div>
    <!-- 标签页 -->
    <el-tabs type="border-card" v-model="activeName" @tab-click="tabChange">
      <el-tab-pane
        v-for="(item, index) in tabOptions"
        :key="index"
        :label="item.name"
        :name="item.name"
        :id="item.id"
      >
        <el-row>
          <el-col :span="24" class="table-box">
            <el-table
              :data="tableData"
              :height="tableHeight"
              @cell-click="showConfig"
              :cell-style="finalCellStyle"
            >
              <el-table-column
                prop="banciNo"
                label="班次"
                width="100"
              ></el-table-column>
              <el-table-column
                :formatter="formatType"
                prop="moveType"
                label="运行方向"
              ></el-table-column>
              <el-table-column
                :label="head"
                v-for="(head, key) in header"
                :key="key"
              >
                <template slot-scope="scope">
                  <div class="table-time">
                    <span>{{
                      scope.row.arr[key] ? scope.row.arr[key].time : "暂无数据"
                    }}</span>
                    <img
                      v-if="scope.row.arr[key].state"
                      :src="srcRow(scope.row.arr[key].state)"
                    />
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <!-- 新增车次 -->
    <el-dialog
      :title="title"
      :visible.sync="configDialog"
      v-if="configDialog"
      width="50%"
    >
      <div class="form search-area" v-if="title == '新增车次'">
        <el-form
          :model="form"
          label-width="102px"
          :rules="rules"
          class="demo-form dialog-form"
        >
          <el-form-item label="发车时间：" prop="addTime">
            <el-time-picker
              v-model="form.addTime"
              format="HH:mm"
              :picker-options="{ selectableRange: lastTime + '-' + nextTime }"
              value-format="HH:mm"
              placeholder="发车时间"
            ></el-time-picker>
          </el-form-item>
          <el-form-item label="行驶时长：" prop="workTime">
            <el-input-number
              size="small"
              controls-position="right"
              :precision="0"
              :min="0"
              v-model="form.workTime"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="发车间隔：" prop="intervalTime">
            <el-input-number
              size="small"
              controls-position="right"
              :precision="0"
              :min="0"
              v-model="form.intervalTime"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="车次状态：" prop="state">
            <el-select v-model="form.state" placeholder="选择车次状态">
              <el-option
                v-for="item in natureOptions"
                :label="item.dictValue"
                :value="Number(item.dictCode)"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注：" prop="remarks">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="输入备注信息(20个字以内)"
              v-model="form.remarks"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 修改车次 -->
      <el-tabs
        type="border-card"
        v-model="form.radio"
        v-if="title != '新增车次'"
      >
        <el-tab-pane label="修改车次" name="0">
          <el-form
            :model="form"
            label-width="102px"
            class="demo-form dialog-form"
          >
            <el-form-item label="发车时间：" prop="addTime">
              <el-time-picker
                v-model="form.addTime"
                format="HH:mm"
                :picker-options="{ selectableRange: lastTime + '-' + nextTime }"
                value-format="HH:mm"
                placeholder="发车时间"
              ></el-time-picker>
            </el-form-item>
            <el-form-item label="行驶时长：" prop="workTime">
              <el-input-number
                size="small"
                controls-position="right"
                :precision="0"
                :min="0"
                v-model="form.workTime"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="发车间隔：" prop="intervalTime">
              <el-input-number
                size="small"
                controls-position="right"
                :precision="0"
                :min="0"
                v-model="form.intervalTime"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="车次状态：" prop="state">
              <el-select v-model="form.state" placeholder="选择车次状态">
                <el-option
                  v-for="item in natureOptions"
                  :label="item.dictValue"
                  :value="Number(item.dictCode)"
                  :key="item.dictCode"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注：" prop="remarks">
              <el-input
                type="textarea"
                :rows="3"
                placeholder="输入备注信息(20个字以内)"
                v-model="form.remarks"
              ></el-input>
            </el-form-item>
            <el-form-item label="联动车次数：" prop="number">
              <el-input-number
                size="small"
                controls-position="right"
                :precision="0"
                :min="0"
                v-model="form.number"
              ></el-input-number>
              <span class="numberNotice"
                >说明：联动车次数不包括当前车次（不联动则默认为0）</span
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="匀点" name="1">
          <el-form
            :model="form"
            label-width="102px"
            label-position="left"
            class="demo-form dialog-form"
          >
            <el-form-item label="发车时间：" prop="addTime">
              <el-time-picker
                v-model="form.addTime"
                :disabled="true"
                format="HH:mm"
                :picker-options="{ selectableRange: lastTime + '-' + nextTime }"
                value-format="HH:mm"
                placeholder="发车时间"
              ></el-time-picker>
            </el-form-item>
            <el-form-item label="匀点方向：" prop="direction">
              <el-select v-model="form.direction" placeholder="请选择方向">
                <el-option label="向前" value="upward"></el-option>
                <el-option label="向后" value="downward"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="匀点车次数：" prop="numberAverage">
              <el-input-number
                size="small"
                controls-position="right"
                :precision="0"
                :min="2"
                v-model="form.numberAverage"
              ></el-input-number>
              <span class="numberNotice">说明：匀点车次数不包括当前车次</span>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>

      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="configDialog = false"
          >取消</el-button
        >
        <el-button
          v-if="form.radio != 1"
          type="primary"
          size="small"
          @click="update"
          >保存</el-button
        >
        <el-button v-else type="primary" size="small" @click="edit"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import lineSelect from '@/components/lineSelect/lineSelect.vue'
import { queryDictsByCodes } from '@/api/lib/api.js'

import {
  getOperateList,
  queryOperateByLineId,
  saveOperate
} from '@/api/lib/bus-api.js'
import moment from "moment"
export default {
  name: 'projectManage',
  components: {
    lineSelect
  },

  data () {
    return {
      tableHeight: 0,
      tabOptions: [],
      natureOptions: [],
      header: [],
      title: "",
      form: {
        radio: 0,
        number: null,
        numberAverage: null,
        direction: "downward",
        remarks: "",
        state: null,
        addTime: null,
        intervalTime: null,
        workTime: null
      },
      rules: {
        name: [{ required: true, message: "不能为空", trigger: "change" }],
        upTime: [{ required: true, message: "不能为空", trigger: "change" }],
        intervalTime: [
          { required: true, message: "不能为空", trigger: "change" },
        ],
        workTime: [
          { required: true, message: "不能为空", trigger: "change" },
        ]
      },
      operateId: null,
      activeName: "1",
      total: 0,
      currentPage: 1,
      pageSize: 10,
      hintOptions: [
        { url: require('@/assets/images/bus/project_oil.png'), val: "加油" },
        { url: require('@/assets/images/bus/project_aerate.png'), val: "加气" },
        { url: require('@/assets/images/bus/project_charge.png'), val: "充电" },
        { url: require('@/assets/images/bus/project_fix.png'), val: "维修" },
        { url: require('@/assets/images/bus/project_chart.png'), val: "包车" },
        { url: require('@/assets/images/bus/project_other.png'), val: "其他" }
      ],
      tableData: [],
      configDialog: false,
      id: null,
      detail: "",
      oldTime: null,
      lastTime: null,
      nextTime: null,
      zhuArr: [],
      fuArr: [],
      x: null,
      y: null,
      zhu: 0,
      fu: 0
    };
  },



  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.projectManage.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = 0
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16 - 40
    },
    // 模糊搜索线路
    getLineData (value) {
      if (value) {
        queryOperateByLineId({ lineId: value }).then(res => {
          if (res.data.length > 0) {
            this.tabOptions = res.data;
            this.activeName = res.data[0].name;
            this.operateId = res.data[0].id;
            this.getListByField()
          } else {
            this.tabOptions = [];
            this.$message.warning("该线路还没有运营方案，请先设置运营方案");
          }
        });

      }
    },

    //根据方案获取车次表
    getListByField () {
      getOperateList({ operateId: this.operateId })
        .then((res) => {
          if (res.code === 1000) {
            let tableData =
              res.data && res.data.length > 0
                ? res.data
                : []
            this.formatData(tableData);
            this.total = res.data.length


          } else {
            this.tableData = []
            this.total = 0
            this.$message.error(res.msg)
          }
          this.searchLoading = false
        })
        .catch(() => {
          this.searchLoading = false
        })
    },
    tabChange (tab) {
      this.operateId = tab.$attrs.id;
      this.getListByField();
    },

    // 单元格样式
    finalCellStyle ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex > 1) {
        var type = row.arr[columnIndex - 2].state;
        if (!type) {
          return "background: rgba(255,255,255,1);cursor:pointer";
        } else {
          if (type > 2 && type < 6) {
            return "background: rgba(12,91,226,0.3);cursor:pointer";
          } else if (type == 6) {
            return "background:rgba(255,18,18,0.3);cursor:pointer";
          } else if (type == 7) {
            return "background: rgba(254,200,0,0.3);cursor:pointer";
          } else {
            if (type != 0) {
              return "background:rgba(204,204,204,0.3);cursor:pointer";
            } else {
              return "cursor:pointer";
            }
          }
        }
      }
    },

    // 单元格图标显示
    srcRow (row) {
      if (row == 3) {
        return this.hintOptions[0].url;
      } else if (row == 4) {
        return this.hintOptions[1].url;
      } else if (row == 5) {
        return this.hintOptions[2].url;
      } else if (row == 6) {
        return this.hintOptions[3].url;
      } else if (row == 7) {
        return this.hintOptions[4].url;
      } else {
        if (row != 0) return this.hintOptions[5].url;
      }
    },
    formatData (tableData) {
      this.zhuArr = [];
      this.fuArr = [];
      // 处理显示的数据
      tableData.forEach((item, index) => {
        let arr = JSON.parse(item.detail);
        this.header = [];
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].moveType == 1) {
            if (arr[i].time != "-" && arr[i].state == 0) {
              this.fuArr.push({ time: arr[i].time, x: i + 1, y: index + 1 });
            }
            this.header.push("副-主");
          } else {
            if (arr[i].time != "-" && arr[i].state == 0) {
              this.zhuArr.push({ time: arr[i].time, x: i + 1, y: index + 1 });
            }
            this.header.push("主-副");
          }
        }
        item.arr = arr;
      });
      this.tableData = [...tableData]
      // 按时间升序排序
      this.zhuArr.sort(function (a, b) {
        return (
          parseInt(a.time.replace(":", "")) - parseInt(b.time.replace(":", ""))
        );
      });
      this.fuArr.sort(function (a, b) {
        return (
          parseInt(a.time.replace(":", "")) - parseInt(b.time.replace(":", ""))
        );
      });
    },

    formatType (row) {
      switch (row.moveType) {
        case 1:
          return "上行";
        case 2:
          return "下行";
      }
    },
    showConfig (row, column, index) {
      if (column.label != "班次" && column.label != "运行方向") {
        this.detail = row.detail;
        this.id = row.id;
        this.x = index.cellIndex - 1;

        // 校验时间在前后车次之间
        this.lastTime = null;
        this.nextTime = null;
        for (var i = this.x; i < row.arr.length; i++) {
          if (row.arr[i].time != "-") {
            this.nextTime = row.arr[i].time + ":00";
            break;
          }
        }
        for (var i = this.x - 2; i > 0; i--) {
          if (row.arr[i].time != "-") {
            this.lastTime = row.arr[i].time + ":00";
            break;
          }
        }
        this.nextTime = this.nextTime == null ? "23:59:59" : this.nextTime;
        this.lastTime = this.lastTime == null ? "00:00:00" : this.lastTime;

        // 弹窗新增编辑回显
        this.oldTime = row.arr[this.x - 1].time;
        this.form.addTime = row.arr[this.x - 1].time;
        this.form.intervalTime = row.arr[this.x - 1].intervalTime;
        this.form.workTime = row.arr[this.x - 1].workTime;
        this.form.state = row.arr[this.x - 1].state;
        this.form.remarks = row.arr[this.x - 1].remarks;

        this.tableData.forEach((item, index) => {
          if (item.banciNo == row.banciNo) {
            this.y = index + 1;
            return;
          }
        });
        this.configDialog = true;
        if (this.form.addTime == "-") {
          this.title = "新增车次";
          this.form.radio = 0;
        } else {
          this.title = "修改车次/匀点";
          this.form.number = 0;
        }
      }
    },

    // 匀点确认
    edit () {
      var nowArr = [];
      var banCis = [];
      var position = null;
      var self = this;
      // 找出会变动的数组
      if (self.tableData[self.y - 1].arr[self.x - 1].moveType == 2) {
        self.zhuArr.forEach((item, index) => {
          if (item.x == self.x && item.y == self.y) {
            position = index;
            return;
          }
        });
        // 向前和向后匀点（主）
        if (self.form.direction == "downward") {
          nowArr = self.zhuArr.slice(
            position,
            position + self.form.numberAverage + 1
          );
        } else {
          nowArr = self.zhuArr.slice(
            position < self.form.numberAverage
              ? 0
              : position - self.form.numberAverage,
            position + 1
          );
        }
      } else {
        self.fuArr.forEach((item, index) => {
          if (item.x == self.x && item.y == self.y) {
            position = index;
            return;
          }
        });
        // 向前和向后匀点（副）
        if (self.form.direction == "downward") {
          nowArr = self.fuArr.slice(
            position,
            position + self.form.numberAverage + 1
          );
        } else {
          nowArr = self.fuArr.slice(
            position < self.form.numberAverage
              ? 0
              : position - self.form.numberAverage,
            position + 1
          );
        }
      }

      //计算第一个点和最后一个点时间差,向下取整
      var reduceTime = parseInt(
        self.filterReduceTime(nowArr[0].time, nowArr[nowArr.length - 1].time) /
        (nowArr.length - 1)
      );
      // 变动数组匀点，并且组装给后台的数组
      nowArr.forEach((item, index) => {
        if (index > 0 && index < nowArr.length - 1) {
          item.time = self.filterArrive(nowArr[index - 1].time, reduceTime);
          self.tableData[item.y - 1].arr[item.x - 1].time = item.time;
          banCis.push({
            id: self.tableData[item.y - 1].id,
            detail: JSON.stringify(self.tableData[item.y - 1].arr)
          });
        }
      });

      saveOperate({ banCis: banCis }).then(res => {
        if (res.code === 1000) {
          self.$message.success("操作成功");
          self.configDialog = false;
          self.getListByField();
        }
      });
    },

    //计算时间差
    filterReduceTime (startTime, endTime) {
      let timestamp =
        new Date(new Date(`2019-01-01 ${endTime}:00`)).getTime() -
        new Date(new Date(`2019-01-01 ${startTime}:00`)).getTime();
      return timestamp / 60000;
    },

    //计算结束时间
    filterArrive (startTime, reduceTime) {
      let timestamp =
        new Date(new Date(`2019-01-01 ${startTime}:00`)).getTime() +
        60000 * reduceTime;
      return moment(timestamp).format("HH:mm");
    },

    // 新增删除编辑车次确认
    update () {
      var nowArr = [];
      var banCis = [];
      var position = null;
      var sameTime = false;
      var sameArr = [];
      var self = this;
      if (self.form.addTime == null) {
        // 删除车次
        self.form.addTime = "-";
        var arr = JSON.parse(self.detail);
        arr[self.x - 1].time = self.form.addTime;
        arr[self.x - 1].intervalTime = self.form.intervalTime;
        arr[self.x - 1].workTime = self.form.workTime;
        arr[self.x - 1].state = self.form.state;
        arr[self.x - 1].remarks = self.form.remarks;
        banCis = [
          {
            id: self.id,
            detail: JSON.stringify(arr)
          }
        ];
      } else if (self.oldTime == "-") {
        // 新增车次
        var coLength = self.tableData[0].arr.length;
        if (
          self.tableData[0].arr[coLength - 1].moveType == 2 &&
          self.tableData[0].arr[coLength - 2].moveType == 2
        ) {
          // 在末尾加车次联动增加空列(单向)
          if (coLength - self.x == 0) {
            self.tableData.forEach(item => {
              item.arr.push(
                {
                  intervalTime: 0,
                  moveType: 2,
                  restTime: 0,
                  state: 0,
                  remarks: "",
                  time: "-",
                  workTime: 0
                },
                {
                  intervalTime: 0,
                  moveType: 2,
                  restTime: 0,
                  state: 0,
                  remarks: "",
                  time: "-",
                  workTime: 0
                }
              );
            });
          } else if (coLength - self.x == 1) {
            self.tableData.forEach(item => {
              item.arr.push({
                intervalTime: 0,
                moveType: 2,
                restTime: 0,
                state: 0,
                remarks: "",
                time: "-",
                workTime: 0
              });
            });
          }
        } else {
          var lastZhu =
            self.tableData[0].arr[coLength - 1].moveType == 2 ? true : false;
          // 在末尾加车次联动增加空列(双向)
          if (coLength - self.x == 0) {
            self.tableData.forEach(item => {
              item.arr.push(
                {
                  intervalTime: 0,
                  moveType: lastZhu ? 1 : 2,
                  restTime: 0,
                  state: 0,
                  remarks: "",
                  time: "-",
                  workTime: 0
                },
                {
                  intervalTime: 0,
                  moveType: lastZhu ? 2 : 1,
                  restTime: 0,
                  state: 0,
                  remarks: "",
                  time: "-",
                  workTime: 0
                }
              );
            });
          } else if (coLength - self.x == 1) {
            self.tableData.forEach(item => {
              item.arr.push({
                intervalTime: 0,
                moveType: lastZhu ? 1 : 2,
                restTime: 0,
                state: 0,
                remarks: "",
                time: "-",
                workTime: 0
              });
            });
          }
        }

        // 验证是否有相同的时间
        sameArr =
          self.tableData[self.y - 1].arr[self.x - 1].moveType == 2
            ? self.zhuArr
            : self.fuArr;
        sameArr.forEach(element => {
          if (element.time == self.form.addTime) {
            sameTime = true;
            return;
          }
        });

        // 将新增的数据放入原数组
        self.tableData[self.y - 1].arr[self.x - 1].time = self.form.addTime;
        self.tableData[self.y - 1].arr[self.x - 1].intervalTime =
          self.form.intervalTime;
        self.tableData[self.y - 1].arr[self.x - 1].workTime =
          self.form.workTime;
        self.tableData[self.y - 1].arr[self.x - 1].state = self.form.state;
        self.tableData[self.y - 1].arr[self.x - 1].remarks = self.form.remarks;

        // 组装给后台的数组
        self.tableData.forEach(item => {
          banCis.push({ id: item.id, detail: JSON.stringify(item.arr) });
        });
      } else {
        // 编辑车次
        if (self.tableData[self.y - 1].arr[self.x - 1].moveType == 2) {
          self.zhuArr.forEach((item, index) => {
            if (item.x == self.x && item.y == self.y) {
              position = index;
              return;
            }
          });
          // 找出会变动的数组
          sameArr = self.zhuArr;
          if (position == null) {
            nowArr = [{ time: self.form.addTime, x: self.x, y: self.y }];
          } else {
            nowArr = self.zhuArr.slice(
              position,
              position + self.form.number + 1
            );
          }
        } else {
          self.fuArr.forEach((item, index) => {
            if (item.x == self.x && item.y == self.y) {
              position = index;
              return;
            }
          });
          // 找出会变动的数组
          sameArr = self.fuArr;
          if (position == null) {
            nowArr = [{ time: self.form.addTime, x: self.x, y: self.y }];
          } else {
            nowArr = self.fuArr.slice(
              position,
              position + self.form.number + 1
            );
          }
        }
        // 变动数组联动，并且组装给后台的数组
        nowArr.forEach((item, index) => {
          if (index > 0) {
            item.time = self.filterArrive(
              nowArr[index - 1].time,
              self.form.intervalTime
            );
            item.intervalTime = nowArr[index - 1].intervalTime;
            item.workTime = nowArr[index - 1].workTime;
            item.state = nowArr[index - 1].state;
            item.remarks = nowArr[index - 1].remarks;
          } else {
            item.time = self.form.addTime;
            item.intervalTime = self.form.intervalTime;
            item.workTime = self.form.workTime;
            item.state = self.form.state;
            item.remarks = self.form.remarks;
          }

          // 验证是否有相同的时间
          sameArr.forEach(element => {
            if (element.time == item.time) {
              if (element.x != item.x || element.y != item.y) {
                sameTime = true;
                return;
              }
            }
          });

          self.tableData[item.y - 1].arr[item.x - 1].time = item.time;
          self.tableData[item.y - 1].arr[item.x - 1].intervalTime =
            self.form.intervalTime;
          self.tableData[item.y - 1].arr[item.x - 1].workTime =
            self.form.workTime;
          self.tableData[item.y - 1].arr[item.x - 1].state = self.form.state;
          self.tableData[item.y - 1].arr[item.x - 1].remarks =
            self.form.remarks;
          banCis.push({
            id: self.tableData[item.y - 1].id,
            detail: JSON.stringify(self.tableData[item.y - 1].arr)
          });
        });
      }
      if (sameTime) {
        self.$message.warning("存在相同的时间，请调整！");
      } else {
        saveOperate({ banCis: banCis }).then(res => {
          if (res.code === 1000) {
            self.$message.success("操作成功");
            self.configDialog = false;
            self.getListByField();
          }
        });
      }
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: 'BUSLX' }).then((res) => {
        if (res.code === 1000) {
          this.natureOptions = res.data.BUSLX
        }
      })
    }
  },
  created () {
    this.getDicts()
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
};
</script>

<style lang="scss" scoped>
.project-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  .el-tabs {
    margin-top: 20px;
  }
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
  .left_con {
    margin-bottom: 15px;
  }
  .right_con {
    width: 150px;
    display: flex;
    justify-content: space-between;
  }
  .mapHintCard {
    position: absolute;
    z-index: 100;
    right: 3%;
    top: 19.5%;
    width: 21vw;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 3%;
    .hintItem {
      color: #000000;
      display: flex;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      align-items: center;
      img {
        margin-right: 2px;
        height: 14px;
      }
    }
  }
  .table-time {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      margin-right: 2px;
    }
  }
  .numberNotice {
    display: block;
    color: red;
    font-size: 13px;
  }
  .el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
}

.theme-project-bus {
  .project-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
